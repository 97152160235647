import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const meta = [
      {
        name: 'robots',
        content: 'noindex, nofollow'
      },
      {
        name: 'AdsBot-Google',
        content: 'noindex, nofollow'
      },
      {
        name: 'googlebot',
        content: 'noindex, nofollow'
      },
      {
        name: 'googlebot-news',
        content: 'noindex, nofollow'
      }
    ]

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title='404: Not Found' meta={meta} />
        <h1>URL Não Encontrada</h1>
        <p>
          Você acabou de tentar acessar uma rota que não existe... Ó tristeza.
        </p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
